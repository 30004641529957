<template>
    <!-- 学会通知详情页 -->
    <div>
        <Details :title="'学会通知'" :articleID="articleID" />
    </div>
</template>

<script>
    import Details from '../comm/details.vue'
    export default {
        components:{
            Details
        },
        computed:{
            articleID(){
                return this.$route.params.id
            }
        },
        created(){
            // console.log(this.$route.params.id);
        }
    }
</script>

<style scoped>

</style>