<template>
<!--学会动态 科技进展 学会通知详情页-->
    <div class="details">
        <topHeader :ind="ind"></topHeader>
        <div class="details_content">
            <div class="details_head">
                首页 > <span>{{title}}</span>
            </div>
            <div class="details_text">
                <div class="details_f">
                    <div class="text_title">
                        {{articleInfo.title}}
                    </div>
                    <div class="text_abstract">
                        {{ articleInfo.abstract }}
                    </div>
                    <template v-if="this.title == '消息通知'">
                        <div class="text_time">
                            {{articleInfo.time}}
                        </div>
                    </template>
                    <template v-else>
                        <div class="text_time">
                            {{articleInfo.publish_date}}<i></i><span>{{articleInfo.view_count}}</span>
                        </div>
                    </template>
                    <div class="text_content" v-html="articleInfo.content">
                        
                    </div>
                </div>
                <div class="details_r">
                    <DetailsMod :totitle="title" :title='detailsTitle' :list='modList'/>
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
    import axios from '../http/axios'
    import DetailsMod from '../comm/detailsmod.vue'
    export default {
        props:['title','articleID'],
        data(){
            return{
                articleInfo:{},
                modList:[]
            }
        },
        components:{
            DetailsMod
        },
        computed:{
            ind(){
                if(this.title == '学会通知'){
                    return 2
                }
                if(this.title == '学会动态'){
                    return 3
                }
                if(this.title == '科技进展'){
                    return 4
                }
                if(this.title == '消息通知'){
                    return 7
                }
            },
            detailsTitle(){
                if(this.title == '学会动态'){
                    return '最新动态'
                }
                if(this.title == '科技进展'){
                    return '最新科技进展'
                }
                if(this.title == '学会通知' || this.title == '消息通知'){
                    return '最新通知'
                }
            }
        },
        watch:{
            articleID(newValue){
                this.getData(newValue)
            }   
        },
        mounted(){
            this.getData(this.articleID)
        },
        methods:{
            getData(id){
                if(this.title == '消息通知'){
                    axios.post('notice/info?notice_id='+id).then(res=>{
                        this.articleInfo = res.data
                        this.modList = res.data.recommend
                    })
                }else{
                    axios.get('article/info?article_id='+id).then(res=>{
                        this.articleInfo = res.data
                        this.modList = res.data.recommend
                    })
                }
            }
        }
    }
</script>

<style scoped lang='less'>
.details_content{
    width: 1140px;
    min-width: 1140px;
    margin: 20px auto 0;
    .details_head{
        padding-bottom: 11px;
        font-size: 16px;
        line-height: 16px;
        color: #333333;
        border-bottom: 2px solid #1D7FD7;
        margin-bottom: 23px;
        span{
            color: #1D7FD7;
        }
    }
    .details_text{
        display: flex;
        justify-content: space-between;
        .details_f{
            width: 820px;
            .text_title{
                font-size: 22px;
                line-height: 28px;
                margin-bottom: 12px;
                color: #333333;
                font-weight: bold;
            }
            .text_abstract{
                color: #666666;
                margin-bottom: 18px;
            }
            .text_time{
                font-size: 14px;
                color: #999999;
                display: flex;
                align-items: center;
                i{
                    display: inline-block;
                    width: 22px;
                    height: 16px;
                    margin-left: 14px;
                    margin-right: 8px;
                    background-image: url('../assets/image/notice/矢量智能对象.png');
                }
                span{
                    font-size: 16px;
                }
            }
            .text_content{
                padding-bottom: 30px;
                // >div{
                //     font-size: 16px;
                //     line-height: 28px;
                //     color: #666666;
                //     text-indent: 1.5em;
                //     margin-top: 20px;
                // }
                // img{
                //     width: 820px;
                //     height: 450px;
                //     border-radius: 10px;
                //     margin-top: 18px;
                // }
            }
        }
        .details_r{
            width: 274px;
        }
    }
}
</style>    