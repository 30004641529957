<template>
    <!-- 详情页右边组件 -->
    <div class="right">
        <div class="title">{{title}}</div>
        <div class="list_r">
            <div v-for="(item,index) in list" :key="item.id">
                <template v-if="totitle == '消息通知'">
                    <div class="list_t">
                        <div class="circle"><i>{{index+1}}</i></div>
                        <router-link :to="toAdress + item.notice.id" class="list_title">{{item.notice.title}}</router-link>
                    </div>
                </template>
                <template v-else>
                    <div class="list_t">
                        <div class="circle"><i>{{index+1}}</i></div>
                        <router-link :to="toAdress + item.id" class="list_title">{{item.title}}</router-link>
                    </div>
                    <div class="view">
                        浏览{{item.view_count}}
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:['title','list','totitle'],
        computed:{
            toAdress(){
                if(this.totitle == '学会动态'){
                    return '/action/'
                }
                if(this.totitle == '科技进展'){
                    return '/progress/'
                }
                if(this.totitle == '学会通知'){
                    return '/notice/'
                }
                if(this.totitle == '消息通知'){
                    return '/info/'
                }
            }
        },
    }
</script>

<style scoped lang='less'>
.right{
    .title{
        position: relative;
        font-size: 18px;
        color: #595959;
        line-height: 32px;
        padding-left: 10px;
        padding-bottom: 6px;
        border-bottom: 1px solid #ECEBEB;
    }
    .title::before{
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        width: 3px;
        height: 20px;
        background-color: #237FC8;
    }
    .list_r{
        >div{
            margin-top: 15px;
            padding-bottom: 14px;
        }
        >div:nth-child(1){
            .circle{
                background-color: #FE6521;
            }
        }
        >div:nth-child(2){
            .circle{
                background-color: #FC9B75;
            }
        }
        >div:nth-child(3){
            .circle{
                background-color: #FBBEA5;
            }
        }
        >div:nth-child(1),>div:nth-child(2),>:nth-child(3){
            .view{
                color: #FC7742;
            }
        }
    }
    .list_t{
        display: flex;
        align-items: flex-start;
        .circle{
            flex-shrink: 0;
            width: 18px;
            height: 18px;
            font-size: 12px;
            border-radius: 50%;
            color: #ffffff;
            margin-right: 9px;
            background-color: #D2D2D2;
            text-align: center;
            line-height: 18px;
            i{
                margin-right: 2px;
            }
        }   
        .list_title{
            font-size: 14px;
            color: #999999;
        }
    }
    .view{
        font-size: 14px;
        color: #999999;
        text-align: right;
    }
}
</style>